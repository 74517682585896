<template>
  <b-modal
    modal-class=""
    ref="cateManageModal"
    :title="title"
    scrollable
    hide-footer
    @hidden="setDefaultValue"
  >
    <b-form autocomplete="off" @submit.stop.prevent="handleCreateCagegoty">
      <b-form-group class="mx-2" label="เพิ่มประเภท:">
        <b-input-group class>
          <b-form-input
            class="addInput required"
            placeholder="ชื่อประเภท(ไทย)"
            size="sm"
            required
            v-model="cateNameTh"
          ></b-form-input>
          <b-form-input
            class="addInput"
            placeholder="ชื่อประเภท(Eng)"
            size="sm"
            v-model="cateNameEn"
          ></b-form-input>
          <template v-slot:append>
            <b-button type="submit" variant="success" size="sm">
              <i class="fas fa-plus"></i>
            </b-button>
          </template>
        </b-input-group>
      </b-form-group>
    </b-form>
    <hr />

    <b-form autocomplete="off">
      <b-table
        id="aptCateTable"
        ref="aptCateTable"
        hover
        small
        sort-icon-left
        head-variant="light"
        :items="categoryList"
        :fields="fields"
      >
        <template v-slot:cell(index)="row">{{ row.index + 1 }}.</template>
        <template v-slot:cell(buttons)="row">
          <b-button-group>
            <b-button
              size="sm"
              variant="outline-secondary"
              @click="showEditModal(row.item)"
            >
              <i class="fas fa-edit"></i>
            </b-button>
            <b-button
              size="sm"
              variant="outline-danger"
              @click="handleDeleteCategory(row.item)"
            >
              <i class="fas fa-trash-alt"></i>
            </b-button>
          </b-button-group>
        </template>
      </b-table>
    </b-form>

    <b-modal
      ref="editCateModal"
      hide-header
      hide-footer
      @hidden="editCategory = []"
    >
      <b-table
        id="aptCateTable"
        ref="aptCateTable"
        class="mb-0"
        small
        head-variant="light"
        :items="editCategory"
        :fields="fields"
      >
        <template v-slot:cell(nameTh)="row">
          <b-form-input
            v-model="row.item.nameTh"
            class="required"
            required
            size="sm"
          ></b-form-input>
        </template>
        <template v-slot:cell(nameEn)="row">
          <b-form-input
            v-model="row.item.nameEn"
            class
            size="sm"
          ></b-form-input>
        </template>
        <template v-slot:cell(buttons)="row">
          <b-button
            size="sm"
            variant="warning"
            @click="handleEditCategory(row.item)"
          >
            แก้ไข
          </b-button></template
        >
      </b-table>
    </b-modal>

    <Dialog ref="Dialog"></Dialog>
    <Loading v-if="isLoading"></Loading>
  </b-modal>
</template>

<script>
import Dialog from "@/components/modal/Dialog";
import Loading from "@/components/Loading";
import "@/theme/modal/AptCateItem.scss";

export default {
  name: "AptItemCateModal",
  components: {
    Dialog,
    Loading,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    categoryList: { type: Array, required: true },
    createCategoryFn: { type: Function, required: true },
    deleteCategoryFn: { type: Function, required: true },
    updateCategoryFn: { type: Function, required: true },
    fetchCategoryFn: { type: Function, required: true },
  },
  data() {
    return {
      isLoading: false,
      cateNameTh: "",
      cateNameEn: "",
      fields: [
        {
          key: "index",
          label: "",
          class: "text-center",
        },
        {
          key: "nameTh",
          label: "ประเภท",
          sortable: true,
        },
        {
          key: "nameEn",
          label: "Category",
          sortable: true,
        },
        {
          key: "buttons",
          label: "",
          class: "text-center",
        },
      ],
      editCategory: [],
    };
  },
  computed: {},
  methods: {
    show() {
      this.$refs["cateManageModal"].show();
    },
    handleCreateCagegoty() {
      this.$refs.Dialog.showAlertConfirm(
        "สร้างประเภทพ ?",
        `คุณต้องการสร้างประเภทพ "${this.cateNameTh}"`
      ).then((result) => {
        if (result.isConfirmed) {
          this.isLoading = true;

          this.createCategoryFn({
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
            event: {
              nameTh: this.cateNameTh,
              nameEn: this.cateNameEn,
            },
          })
            .then((res) => {
              this.updateList();
            })
            .catch((err) => {
              console.error(err);
            })
            .finally((res) => {
              this.isLoading = false;
            });
        }
      });
    },
    handleDeleteCategory(item) {
      this.$refs.Dialog.showAlertConfirm(
        "ลบประเภทพ ?",
        `คุณต้องการสร้างประเภทพ "${item.nameTh}"`
      ).then((result) => {
        if (result.isConfirmed) {
          this.isLoading = true;
          this.deleteCategoryFn({
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
            id: item.id,
          })
            .then((res) => {
              this.updateList();
            })
            .catch((err) => {
              console.error(err);
            })
            .finally((res) => {
              this.isLoading = false;
            });
        }
      });
    },
    handleEditCategory(item) {
      this.$refs.Dialog.showAlertConfirm(
        "แก้ไขประเภทพ ?",
        `คุณต้องการแก้ไขประเภทพ "${item.nameTh}"`
      ).then((result) => {
        if (result.isConfirmed) {
          this.isLoading = true;
          this.updateCategoryFn({
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
            event: item,
          })
            .then((res) => {
              this.updateList();
            })
            .catch((err) => {
              console.error(err);
            })
            .finally((res) => {
              this.isLoading = false;
              this.hideEditModal();
            });
        }
      });
    },
    updateList() {
      this.fetchCategoryFn({
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
      });
    },
    showEditModal(item) {
      this.editCategory = [item];
      this.$refs["editCateModal"].show();
    },
    hideEditModal() {
      this.$refs["editCateModal"].hide();
    },
    setDefaultValue() {
      this.cateNameTh = "";
      this.cateNameEn = "";
      this.editCategory = [];
    },
  },
};
</script>

<style>
</style>