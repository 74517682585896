<template>
  <b-modal
    modal-class="component-modal-aptitem"
    id="AptItemModal"
    ref="AptItemModal"
    size="lg"
    title="รายการนัดหมาย"
    hide-footer
    no-close-on-backdrop
    @close="close"
    @hide="close"
    @hidden="setDefaultValue"
  >
    <b-form @submit.prevent="saveAptItem">
      <b-form-group label-cols="3" label="ชื่อรายการ(ไทย):" label-align="right">
        <b-form-input
          class="required"
          v-model="appt.nameTh"
          :class="{ 'is-invalid': $v.appt.nameTh.$error }"
        ></b-form-input>
      </b-form-group>
      <b-form-group label-cols="3" label="ชื่อรายการ(Eng):" label-align="right">
        <b-form-input v-model="appt.nameEn"></b-form-input>
      </b-form-group>

      <b-form-group label-cols="3" label="ระยะเวลารักษา:" label-align="right">
        <b-input-group class="p-0" append="นาที">
          <b-form-input
            class="col"
            type="range"
            v-model="appt.durationMin"
            :min="duration"
            :max="180"
            :step="duration"
            number
          ></b-form-input>
          <b-form-input
            class="col-2"
            v-model="appt.durationMin"
            number
          ></b-form-input>
        </b-input-group>
      </b-form-group>
      <b-form-group label-cols="3" label="ระยะห่างการนัด:" label-align="right">
        <b-input-group class="p-0" append="วัน">
          <b-form-input
            class="col"
            type="range"
            v-model="slideValue"
            min="0"
            :max="daySteps.length - 1"
            step="1"
          ></b-form-input>
          <b-form-input
            class="col-2"
            v-model.lazy.number="appt.nextApptDay"
            type="number"
          ></b-form-input>
        </b-input-group>
      </b-form-group>
      <b-form-group
        label-cols="3"
        label="แจ้งเตือน:"
        label-align="right"
        description="ตั้งค่าเริ่มต้น 'การแจ้งเตือน' สำหรับรายการนี้"
      >
        <b-form-select
          class="col"
          :options="reminderList"
          value-field="val"
          text-field="label"
          v-model="appt.rmdId"
        >
          <template v-slot:first>
            <b-form-select-option :value="null"
              >-- ไม่มีการแจ้งเตือน --</b-form-select-option
            >
          </template>
        </b-form-select>
      </b-form-group>
      <b-form-row>
        <div class="col-3 text-right align-self-center">ประเภท:</div>
        <b-form-select
          v-model="appt.cateId"
          class="col mx-1 required"
          :options="getTreatmentCateList"
          value-field="id"
          text-field="nameTh"
          :class="{ 'is-invalid': $v.appt.cateId.$error }"
        >
          <template v-slot:first>
            <b-form-select-option :value="null" disabled
              >-- เลือกประเภท --</b-form-select-option
            >
          </template>
        </b-form-select>
        <b-button
          class="col-1 mx-1"
          block
          variant="secondary"
          @click="openAptCateItem()"
        >
          <i class="fas fa-edit"></i>
        </b-button>
      </b-form-row>
      <hr />
      <b-row class="d-flex flex-row-reverse">
        <div>
          <b-button class="ml-2 mr-3" type="submit" variant="primary">
            {{ appt.id ? "แก้ไขรายการ" : "สร้างรายการ" }}
          </b-button>
        </div>
        <div>
          <b-button variant="danger" v-if="appt.id" @click="deleteAptItem(appt)"
            >ลบรายการ</b-button
          >
        </div>
      </b-row>
    </b-form>
    <Dialog ref="Dialog"></Dialog>
    <Loading v-if="isLoading"></Loading>
    <CateManage
      ref="AptCateItem"
      title="ประเภทนัดหมาย"
      :categoryList="getTreatmentCateList"
      :createCategoryFn="createAppointmentCate"
      :deleteCategoryFn="deleteAppointmentCate"
      :updateCategoryFn="updateAppointmentCate"
      :fetchCategoryFn="fetchTreatmentCateList"
    />
  </b-modal>
</template>

<script>
import Dialog from "@/components/modal/Dialog";
import Loading from "@/components/Loading";
import CateManage from "@/components/modal/CateManage";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import "@/theme/modal/AptItem.scss";
import {
  required,
  requiredIf,
  numeric,
  minLength,
  between,
  sameAs,
  not,
  helpers,
  email,
} from "vuelidate/lib/validators";

export default {
  name: "AptItemModal",
  components: {
    Dialog,
    Loading,
    CateManage,
  },
  data() {
    return {
      isLoading: false,
      appt: {
        id: null,
        nameTh: "",
        nameEn: "",
        durationMin: 30,
        nextApptDay: 7,
        cateId: null,
        favorite: false,
        rmdId: null,
      },
      daySteps: [
        1, 2, 3, 4, 5, 6, 7, 10, 14, 21, 30, 45, 60, 90, 120, 180, 365,
      ],
      slideValue: 6,
    };
  },
  validations: {
    appt: {
      nameTh: { required },
      cateId: { required },
    },
  },
  computed: {
    ...mapGetters({
      getTreatmentCateList: "moduleAppointment/getTreatmentCateList",
      getReminderList: "moduleMaster/getReminderList",
      getBranchInfo: "moduleUser/getBranchInfo", //confApptDuration
    }),
    reminderList() {
      return this.getReminderList.filter((i) => i.val <= 10);
    },
    duration() {
      return moment
        .duration(
          this.getBranchInfo?.confApptDuration
            ? this.getBranchInfo.confApptDuration
            : "00:15"
        )
        .asMinutes();
    },
  },
  watch: {
    slideValue() {
      this.appt.nextApptDay = this.daySteps[this.slideValue];
    },
    "appt.nextApptDay"() {
      if (this.daySteps.indexOf(this.appt.nextApptDay) === -1) {
        return;
      }
      this.slideValue = this.daySteps.indexOf(this.appt.nextApptDay);
    },
  },
  methods: {
    ...mapActions({
      createAppointmentCate: "moduleAppointment/createAppointmentCate",
      updateAppointmentCate: "moduleAppointment/updateAppointmentCate",
      deleteAppointmentCate: "moduleAppointment/deleteAppointmentCate",
      fetchTreatmentCateList: "moduleAppointment/fetchTreatmentCateList",
    }),
    show(aptItem) {
      if (aptItem) {
        this.appt = Object.assign({}, aptItem);
      }
      this.$bvModal.show("AptItemModal");
    },
    saveAptItem() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      if (!this.appt.id) {
        this.isLoading = true;
        this.$store
          .dispatch("moduleAppointment/createTreatmentList", {
            event: this.appt,
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
          })
          .then(() => {
            this.$parent.showDialogToast(
              "success",
              `สร้างรายการ "${this.appt.nameTh}" สำเร็จ`
            );
            this.$bvModal.hide("AptItemModal");
          })
          .catch(() => {
            this.showDialogToast(
              "warning",
              `สร้างรายการ "${this.appt.nameTh}" ไม่สำเร็จ`
            );
          })
          .finally(() => {
            this.isLoading = false;
            this.setDefaultValue();
          });
      } else {
        this.$refs.Dialog.showAlertConfirm(
          "แก้ไขรายการนัดหมาย ?",
          "คุณต้องการแก้ไขรายการนัดหมาย"
        ).then((result) => {
          if (result.value) {
            this.isLoading = true;
            this.$store
              .dispatch("moduleAppointment/updateTreatmentList", {
                event: this.appt,
                clinicUrl: this.$route.params.clinicUrl,
                branchUrl: this.$route.params.branchUrl,
              })
              .then(() => {
                this.$parent.showDialogToast(
                  "success",
                  `แก้ไขรายการ "${this.appt.nameTh}" สำเร็จ`
                );
                this.setDefaultValue();
                this.$bvModal.hide("AptItemModal");
              })
              .catch(() => {
                this.$parent.showDialogToast(
                  "warning",
                  `แก้ไขรายการ "${this.appt.nameTh}" ไม่สำเร็จ`
                );
              })
              .finally(() => {
                this.isLoading = false;
              });
          }
        });
      }
    },
    deleteAptItem(eventData) {
      this.$refs.Dialog.showAlertConfirm(
        "ลบรายการนี้ ?",
        `คุณต้องการลบรายการ "${eventData.nameTh}"`
      ).then((result) => {
        if (result.value) {
          this.isLoading = true;
          this.$store
            .dispatch("moduleAppointment/deleteTreatmentList", {
              event: eventData,
              clinicUrl: this.$route.params.clinicUrl,
              branchUrl: this.$route.params.branchUrl,
            })
            .then((res) => {
              if (res.status == "200") {
                this.setDefaultValue();
                this.$bvModal.hide("AptItemModal");
                this.$parent.showDialogToast(
                  "success",
                  `ลบรายการ "${eventData.nameTh}" สำเร็จ`
                );
              }
              this.isLoading = false;
            })
            .catch(() => {
              this.isLoading = false;
              this.$parent.showDialogToast(
                "warning",
                `ลบรายการ "${eventData.nameTh}" ไม่สำเร็จ`
              );
            });
        }
      });
    },
    close() {
      this.$v.$reset();
      this.setDefaultValue();
    },
    setDefaultValue() {
      this.appt = Object.assign(
        {},
        {
          id: null,
          nameTh: "",
          nameEn: "",
          durationMin: 15,
          nextApptDay: 7,
          cateId: null,
          favorite: false,
          rmdId: null,
        }
      );
    },
    openAptCateItem() {
      this.$refs.AptCateItem.show();
    },
  },
};
</script>

<style>
.required {
  background-color: #ccecff6c;
}
</style>