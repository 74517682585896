<template>
  <div class="content-wrapper view-aptlist-manage pt-3">
    <div class="content">
      <div class="card">
        <div class="card-body">
          <h4><i class="fas fa-list"></i> ลิสต์นัดหมาย</h4>
          <div class="row mx-1 my-3">
            <b-form-input
              type="search"
              class="col required"
              autofocus
              v-model="filter"
              placeholder="ค้นหารายการนัดหมายจาก ชื่อ หรือ ประเภท"
            ></b-form-input>
            <b-button
              v-if="isCounterOrManager"
              class="col-5 col-sm-4 col-md-3 ml-3"
              variant="primary"
              @click="openAptItemModal()"
            >
              <i class="fas fa-plus"></i> เพิ่มรายการ
            </b-button>
          </div>

          <b-table
            id="appointmentListManageTable"
            ref="appointmentListManageTable"
            hover
            small
            sort-icon-left
            sort-by="creationDt"
            :sort-desc="true"
            head-variant="light"
            :items="treatmentList"
            :fields="fields"
            :filter="filter"
            :responsive="true"
          >
            <template v-slot:cell(index)="row">{{ row.index + 1 }}</template>
            <template v-slot:cell(creationDt)="row">{{
              formatDate(row.value)
            }}</template>
            <template v-slot:cell(buttons)="row">
              <b-button-group class="show-when-hovered" size="xs">
                <b-button
                  variant="outline-info"
                  :pressed="row.item.favorite"
                  @click="favToggle(row.item)"
                >
                  <i class="far fa-star"></i>
                </b-button>
                <b-button
                  v-if="isCounterOrManager"
                  class=""
                  variant="outline-secondary"
                  @click="openAptItemModal(row.item)"
                >
                  <i class="fas fa-edit"></i>
                </b-button>
                <b-button
                  v-if="isCounterOrManager"
                  variant="outline-danger"
                  @click="deleteAptItem(row.item)"
                >
                  <i class="fas fa-trash-alt"></i>
                </b-button>
              </b-button-group>
            </template>
            <template #cell(rmdId)="row">
              {{ getReminderName(row.item.rmdId) }}
            </template>
          </b-table>
        </div>
      </div>
      <AptItem ref="AptItemModal"></AptItem>
      <Dialog ref="Dialog"></Dialog>
      <Loading v-if="isLoading"></Loading>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import AptItem from "@/components/modal/AptItem";
import Dialog from "@/components/modal/Dialog";
import Loading from "@/components/Loading";
import moment from "moment";

export default {
  name: "AptListManage",
  components: {
    AptItem,
    Dialog,
    Loading,
  },
  data() {
    return {
      isLoading: false,
      fields: [
        {
          key: "index",
          label: "",
          class: "text-center",
        },
        {
          key: "nameTh",
          label: "รายการนัดหมาย",
          sortable: true,
          class: "narrow-spacing",
        },
        {
          key: "nameEn",
          label: "รายการนัดหมาย(Eng)",
          sortable: true,
          class: "narrow-spacing",
        },

        {
          key: "cateName",
          label: "ประเภท",
          sortable: true,
          class: "narrow-spacing",
        },
        {
          key: "durationMin",
          label: "ระยะเวลา(นาที)",
          class: "text-right",
        },
        {
          key: "durationMin",
          label: "ระยะเวลา(นาที)",
          class: "text-right",
        },
        {
          key: "nextApptDay",
          label: "นัดครั้งต่อไป(วัน)",
          class: "text-right",
        },
        {
          key: "rmdId",
          label: "แจ้งเตือน",
          class: "text-center",
        },
        {
          key: "creationDt",
          label: "วันที่สร้าง",
          sortable: true,
          class: "narrow-spacing",
        },
        {
          key: "buttons",
          label: "",
          class: "text-center",
        },
      ],
      filter: null,
      // reminderList: [],
      hoveredIndex: null,
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapGetters({
      getTreatmentList: "moduleAppointment/getTreatmentList",
      getTreatmentCateBy: "moduleAppointment/getTreatmentCateBy",
      // reminderList: "moduleMaster/getReminderList",

      getReminderBy: "moduleMaster/getReminderBy",
      isAccessMenu: "moduleUser/getIsAccessMenu",
    }),
    treatmentList() {
      const treatmentList = [];
      this.getTreatmentList.forEach((element) => {
        element.cateName = this.getTreatmentCateName(element);
        // element.creationDtShow = moment(element.creationDt)
        //   .locale("th")
        //   .add(543, "year")
        //   .format("DD MMM YY");
        treatmentList.push(element);
      });
      return treatmentList;
    },
    isCounterOrManager() {
      return this.isAccessMenu(["COUNTER", "MANAGER", "HEADCOUNTER"]);
    },
  },
  watch: {},
  methods: {
    ...mapActions({
    }),
    init() {
    },
    moment() {
      return moment();
    },
    openAptItemModal(aptItem) {
      this.$refs.AptItemModal.show(aptItem);
    },
    favToggle(data) {
      this.isLoading = true;
      if (data.favorite == false) {
        this.$store
          .dispatch("moduleAppointment/updateTreatmentFavorite", {
            event: data,
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else if (data.favorite == true) {
        this.$store
          .dispatch("moduleAppointment/deleteTreatmentFavorite", {
            event: data,
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    deleteAptItem(eventData) {
      this.$refs.Dialog.showAlertConfirm(
        "ลบรายการนี้ ?",
        `คุณต้องการลบรายการ "${eventData.nameTh}"`
      ).then((result) => {
        if (result.value) {
          this.isLoading = true;
          this.$store
            .dispatch("moduleAppointment/deleteTreatmentList", {
              event: eventData,
              clinicUrl: this.$route.params.clinicUrl,
              branchUrl: this.$route.params.branchUrl,
            })
            .then((res) => {
              if (res.status == "200") {
                this.isLoading = false;
                this.showDialogToast(
                  "success",
                  `ลบรายการ "${eventData.nameTh}" สำเร็จ`
                );
              }
            })
            .catch((res) => {
              this.isLoading = false;
              this.showDialogToast(
                "warning",
                `ลบรายการ "${eventData.nameTh}" ไม่สำเร็จ`
              );
            });
        }
      });
    },
    showDialogToast(variant, textDetail) {
      this.$refs.Dialog.showToast(variant, textDetail);
    },
    getTreatmentCateName(val) {
      if (this.getTreatmentCateBy(val.cateId, "id")) {
        return this.getTreatmentCateBy(val.cateId, "id").nameTh;
      } else {
        return "";
      }
    },
    getReminderName(val) {
      if (this.getReminderBy(val, "val")) {
        return this.getReminderBy(val, "val").label;
      } else {
        return "-";
      }
    },
    formatDate(date) {
      if (date) {
        return moment(date).locale("th").format("D MMM YY");
      } else {
        return "";
      }
    },
  },
  updated() {},
    destroyed() {
    this.$store.dispatch("moduleAppointment/fetchAppointmentCateAndList", {
      clinicUrl: this.$route.params.clinicUrl,
      branchUrl: this.$route.params.branchUrl,
    });
  },
};
</script>

<style scoped>
</style>
